.header-orn {
  width: 100%;
  height: 100vh;
  background-color: #003c7e;
  position: relative;
}

.header-orn::before {
  display: block;
  content: "";
  width: 100%;
  height: 0.4rem;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}

*,
*::before,
*::after {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.my-custom-model {
  width: calc(100% - 2rem);
}

.my-custom-model .am-modal-content,
.my-custom-model .am-modal-body {
  background-color: transparent;
  padding: 0 !important;
  overflow: visible;
}

.my-custom-model .model-close {
  margin-top: 0.64rem;
}

.my-custom-popup .am-modal-content,
.my-custom-popup .am-modal-body {
  background-color: transparent;
  padding: 0 !important;
  overflow: visible;
}

.popup-content-datepicker {
  background: #fff;
  border-radius: 0.693333rem 0.693333rem 0 0;
}

.popup-content-datepicker .picker-control {
  padding: 0.533333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-content-datepicker .picker-control div[class^=picker-control-button__] {
  width: 2.586667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  border-radius: 0.586667rem;
}

.popup-content-datepicker .picker-control div[class^=picker-control-button__]:nth-child(1) {
  background: rgba(0,60,126,.05);
  color: #33aba4;
}

.popup-content-datepicker .picker-control div[class^=picker-control-button__]:nth-child(2) {
  background: #33aba4;
  color: #fff;
}

.popup-content-datepicker .am-picker {
  background: #fff;
}

.one-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.two-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -moz-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

input::-webkit-input-placeholder {
  vertical-align: middle;
}

.zhr_ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.adm-infinite-scroll {
  width: 100%;
}

.wx-share-preload-image {
  position: absolute;
  width: 100vw;
  left: -100vw;
  visibility: hidden;
}

body {
  font-family: "SF Pro SC","SF Pro Display","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.adm-safe-area-position-bottom {
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
}