html,
body,
#root {
  width: 100%;
  height: 100%;
}

div,
section,
header,
nav {
  box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
fieldset,
blockquote,
pre,
table,
th,
td,
input,
textarea {
  margin: 0;
  padding: 0;
}

html {
  font-family: "Microsoft Yahei", Arial, Tahoma, sans-serif;
  font-size: 75%;
}

img,
fieldset,
abbr,
acronym {
  border: 0;
}

ul,
li,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  outline: 0;
}

a:link,
a:visited {
  text-decoration: none;
}

:focus {
  outline: 0;
}

q:before,
q:after {
  content: "";
}

q {
  quotes: none;
}

* html iframe,
* html frame {
  overflow: auto;
}

em {
  font-style: normal;
}

b,
strong {
  /* font-weight: normal; */
}

caption,
th {
  text-align: left;
}

address,
caption,
cite,
code,
th,
var {
  font-weight: normal;
  font-style: normal;
}

/**
 * Address styling not present in Safari and Chrome.
 */

dfn {
  font-style: italic;
}

/**
 * Contain overflow in all browsers.
 */

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  _font-family: "courier new", monospace;
  font-size: 1em;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/**
 * Address differences between Firefox and other browsers.
 */

hr {
  box-sizing: content-box;
  height: 0;
}

/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

input,
textarea,
select,
button {
  font-family: "Microsoft Yahei", Arial, Tahoma, sans-serif;
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
  overflow: visible;
}

textarea {
  resize: none;
  overflow: auto;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
  line-height: normal;
  outline: none;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 0.026667rem solid #c0c0c0;
  margin: 0 0.053333rem;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */

legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/* 清理浮动 */

.clearfix:after {
  content: "\200B";
  display: block;
  height: 0;
  clear: both;
}

.clearfix {
  /* IE < 8 */
  zoom: 1;
}

/* 入住评估模块公共样式 */

.evaluate-common-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* 表格操作项按钮公共样式 */

.table-edit-button {
  padding-right: 0.266667rem;
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  touch-action: manipulation;
  white-space: nowrap;
}

.table-edit-button:hover,
.table-edit-button:active {
  text-decoration: none;
  outline: 0;
}

.table-edit-button:hover {
  color: #40a9ff;
}

.table-edit-button:active {
  color: #096dd9;
}

/*可操作，但避免操作按钮，比如，退回、作废类功能按钮*/

.table-cacel-button {
  padding-right: 0.266667rem;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  touch-action: manipulation;
  white-space: nowrap;
}

.table-cacel-button:hover,
.table-cacel-button:active {
  text-decoration: none;
  outline: 0;
}

.table-cacel-button:hover {
  color: rgba(0, 0, 0, 0.65);
}

.table-cacel-button:active {
  color: rgba(0, 0, 0, 0.65);
}

/*失效功能按钮*/

.table-lose-button {
  padding-right: 0.266667rem;
  color: rgba(255, 4, 4, 0.65);
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  touch-action: manipulation;
  white-space: nowrap;
}

.table-lose-button:hover {
  text-decoration: none;
  outline: 0;
  cursor: not-allowed;
  color: rgba(255, 4, 4, 0.5);
}

/*不可点击按钮，或已操作按钮*/

.table-disable-button {
  padding-right: 0.266667rem;
  color: rgba(0, 0, 0, 0.65);
  opacity: 0.4;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  touch-action: manipulation;
  white-space: nowrap;
}

.table-disable-button:hover {
  text-decoration: none;
  outline: 0;
  color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}

.global-horizontal-space {
  padding-left: 0.533333rem;
  padding-right: 0.533333rem;
}

@font-face {
  font-family: "Antonin Bold";
  src: url(https://sddq.oss-cn-beijing.aliyuncs.com/Antonio-Bold.ttf);
}
.header-orn {
  width: 100%;
  height: 100vh;
  background-color: #003c7e;
  position: relative;
}

.header-orn::before {
  display: block;
  content: "";
  width: 100%;
  height: 0.4rem;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}

*,
*::before,
*::after {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.my-custom-model {
  width: calc(100% - 2rem);
}

.my-custom-model .am-modal-content,
.my-custom-model .am-modal-body {
  background-color: transparent;
  padding: 0 !important;
  overflow: visible;
}

.my-custom-model .model-close {
  margin-top: 0.64rem;
}

.my-custom-popup .am-modal-content,
.my-custom-popup .am-modal-body {
  background-color: transparent;
  padding: 0 !important;
  overflow: visible;
}

.popup-content-datepicker {
  background: #fff;
  border-radius: 0.693333rem 0.693333rem 0 0;
}

.popup-content-datepicker .picker-control {
  padding: 0.533333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-content-datepicker .picker-control div[class^=picker-control-button__] {
  width: 2.586667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  border-radius: 0.586667rem;
}

.popup-content-datepicker .picker-control div[class^=picker-control-button__]:nth-child(1) {
  background: rgba(0,60,126,.05);
  color: #33aba4;
}

.popup-content-datepicker .picker-control div[class^=picker-control-button__]:nth-child(2) {
  background: #33aba4;
  color: #fff;
}

.popup-content-datepicker .am-picker {
  background: #fff;
}

.one-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.two-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -moz-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

input::-webkit-input-placeholder {
  vertical-align: middle;
}

.zhr_ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.adm-infinite-scroll {
  width: 100%;
}

.wx-share-preload-image {
  position: absolute;
  width: 100vw;
  left: -100vw;
  visibility: hidden;
}

body {
  font-family: "SF Pro SC","SF Pro Display","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.adm-safe-area-position-bottom {
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
}
